import Vue from 'vue'
//this requires the collapsible sections mixin to be included in any vue apps (pages) that include this component
//changed from mixin to component to support multiple tabby areas on one page

var config = {
  animationTiming: 350 // should be in line with animation.scss file
}

Vue.component('TabbyArea', {
  // mixins: [collapsibleSections],
    props: ['initialActiveTabLabel'],
    data: function () {
        return {
        isMobile: false,
        showMobileTabNav: false,
        activeTabIndex: 0,
        tabProcessingAnimation: false,
        tabFrameHeight: '',
        activeTabLabel: '',
        //when a read more is collapsed, scroll up to this element, and not the beginning of the read more 
        //in order to see the tabs
        readMoreScrollOverride: '',
        }
    },
    
    created () {
      this.activeTabLabel = this.initialActiveTabLabel
    },

    watch: {
        activeTabIndex (newValue, oldValue) {
        var tabFrameChildren = this.$refs.tabframe.children

        this.tabProcessingAnimation = false

        window.requestAnimationFrame(() => {
            Vue.nextTick(() => {
            this.tabFrameHeight = tabFrameChildren[newValue].clientHeight + 'px'
            })
        })

        setTimeout(() => {
            this.tabFrameHeight = 'auto'
        }, config.animationTiming)
        }
    },
    mounted () {
        this.readMoreScrollOverride = 'tabs-' + this._uid;
    },
    methods: {
        isActiveTab (givenIndex) {
        return this.activeTabIndex === givenIndex
        },
        setActiveTab (newActiveIndex, buttonLabel) {
            console.log(this.$refs.tabframe);
        if (this.tabProcessingAnimation) { return }
        if (this.isActiveTab(newActiveIndex)) { return }

        (isNaN(newActiveIndex))
            ? console.log('please supply an integer')
            : this.startTabTransition(newActiveIndex, buttonLabel)

        this.showMobileTabNav = false
        },

        startTabTransition (newActiveIndex, buttonLabel) {

        var tabFrameChildren = this.$refs.tabframe.children

        this.tabProcessingAnimation = true

        window.requestAnimationFrame(() => {
            Vue.nextTick(() => {
            this.tabFrameHeight = tabFrameChildren[this.activeTabIndex].clientHeight + 'px'
            })
        })

        var calcHeight = this.$refs.tabframenav.querySelector('.tabby-area__nav-toggle').offsetHeight + this.$refs.tabframenav.querySelector('.tabby-area__nav-options').offsetHeight + 16 // offsetting margins
        var contentHeight = tabFrameChildren[newActiveIndex].querySelector('.read-more').offsetHeight + 56 // offsetting margins/line height of nav dropdown
        var finalHeight = contentHeight > calcHeight ? 'auto' : calcHeight + 'px'

        setTimeout(() => {
            Vue.nextTick(() => {
            this.activeTabIndex = newActiveIndex
            this.activeTabLabel = buttonLabel
            })
        }, config.animationTiming)

        document.getElementById('relatedExperience').style.height = finalHeight;
        },
        toggleTabDropdown () {
        this.showMobileTabNav = !this.showMobileTabNav
        },
    }
})
