import Vue from 'vue'
import { TweenLite } from 'gsap'

var scrollConfig = {
  duration: .5,
  offset: 150,
}

Vue.component('read-more', {
  props: ['separateIntro'], //prop determines if we are dealing with two fields
  data: function () {
    return {
      isExpanded: false,
      cachedHeight: '',
      collapsedHeight: '',
      isContentExpandable: false,
      scrollID: ''
    }
  },
  mounted () {
    this.isContentExpandable = (this.separateIntro)
      ? true
      : (this.$refs.collapsedContent.scrollHeight > this.$refs.collapsedContent.dataset.readmoreheight)
    this.scrollID = 'readmore-' + this._uid;
  },
  
  computed: {
    shouldScrollOnCollapse () {
      var hiddenContentHeight = this.$refs.collapsedContent.scrollHeight;
      var deviceHeight = document.documentElement.clientHeight; 

      if(!hiddenContentHeight || !deviceHeight)
        return false;

      //scroll if the hidden content is more than half the screen height
      return hiddenContentHeight >  deviceHeight / 2;
    }
  },

  methods: {
    toggleReadMore () {

      const el = this.$refs.collapsedContent;
      const sectionHeight = el.scrollHeight;
      var shouldExpand = !this.isExpanded;
      var relatedExperience = el.closest('#relatedExperience');

      if (shouldExpand && relatedExperience != null) {
        relatedExperience.style.height = 'auto';
      }

      (shouldExpand)
      ? this.expandContent(sectionHeight)
      : this.collapseContent(sectionHeight)

      this.removeListener(el, shouldExpand)
    },
    expandContent (heightToExpand) {
      this.isExpanded = !this.isExpanded
      this.cachedHeight = this.$refs.collapsedContent.clientHeight

      window.requestAnimationFrame(() => {
        this.collapsedHeight = heightToExpand + 'px'
      })
    },
    collapseContent (currentHeight) {
      //scroll first, then collapse, otherwise it gets glitchy
      if(this.shouldScrollOnCollapse) {

        //for tabs (and maybe others) we want to scroll up to where we can see the tabs and not just the beginning of the read more
        var elementToScrollTo = this.scrollID;
        if(this.$parent.readMoreScrollOverride){
          elementToScrollTo = this.$parent.readMoreScrollOverride
        }

        TweenLite.to(window, scrollConfig.duration, {
          scrollTo: {
            y: `#${elementToScrollTo}`,
            offsetY: scrollConfig.offset,
            autoKill: false,
          },
          onComplete: this.collapseContentUpdateDOM,
          onCompleteParams: [currentHeight]
        })
      }
      else {
        this.collapseContentUpdateDOM(currentHeight);
      }
    },
    //post scroll, if we scroll
    collapseContentUpdateDOM (currentHeight) {
      window.requestAnimationFrame(() => {
        this.isExpanded = !this.isExpanded;
     
        window.requestAnimationFrame(() => {
          this.collapsedHeight = currentHeight + 'px'

          window.requestAnimationFrame(() => {
            this.collapsedHeight = this.cachedHeight + 'px'
          })
        })
      })
    },
    removeListener (element, expandState) {
      element.addEventListener('transitionend', () => {
        // remove this event listener so it only gets triggered once
        element.removeEventListener('transitionend', void (0))

        if (expandState) {
          // remove "height" from the element's inline styles, so it can return to its initial value
          this.collapsedHeight = 'auto'
        } else {
          // remove "height" from the element's inline styles, so it can return to its initial value
          this.collapsedHeight = this.cachedHeight + 'px'
        }
      })
    }
  }
})
