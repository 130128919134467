// This mixin is used for site search, insights search, lawyer search, and staff search


import axios from 'axios'
import { TweenLite } from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

const convertFiltersToString = (activeFilters) => {
  return activeFilters.map(filter => {
    return `${filter.key}=${encodeURIComponent(filter.value)}`
  })
}

var scrollConfig = {
  duration: 1,
  offset: 85,
  locationID: 'search-updates'
}

export default ({ apiEndpoint, transitionTime }) => ({
  data: function () {
    return {
      keywordSearchModel: window.json && window.json.keyword ? window.json.keyword: '',
      //the search needs needs to initialize the empty filters from this JSON data
      activeFilters: window.json? window.json.initialFiltersState : [],
      selectDropdowns: window.json? window.json.selectDropdownsModels: {},
      isAdvancedSearchExpanded: window.json && !!window.json.isAdvancedSearchExpanded? true : false,
      searchResults:  [],
      totalResults : 0,
      page: window.json && window.json.page ? window.json.page : 0,
      //on refresh if we have filters applied and the page is non zero
      loadAllResultsUpToPage: window.json && window.json.page ? true : false,
      isSearchExecuted: false,
    }
  },
  mounted () {
    this.replaceWindowState()
    this.setupPopstateEvent()
    
    //set dropdown filter models and set label names
    this.setPopulatedDropdownsOnRender()

    //run a query if any filters were set from the query string
    if( this.hasFiltersApplied) {
      this.performSearchQuery(true); //use initial search callback
      //get all results only for the very first search 
      this.loadAllResultsUpToPage = false;
    }
    else {
      //remove the initial class if there is no ajax search to wait for
      this.removeInitialClass();
    }

  },  
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    hasAnyResults () {
      return this.searchResults.length > 0
    },
    showNoResultsMessage () {
      return this.isSearchExecuted && !this.hasAnyResults
    },
    hasFiltersApplied () {
      return this.activeFilters.some(this.checkAnyAppliedFilter)
    },
    hasKeywordFilter () {
      return this.isThisFilterApplied('keyword')
    },
    hasDropdownsFilters () {
      return (this.getAppliedDropdowns.length > 0)
    },
    getArrayofDropdownModels () {
      return Object.keys(this.selectDropdowns)
    },
    getAppliedDropdowns () {
      let activeFilters = this.getArrayofDropdownModels.reduce((accumulator, filterName) => {
        if (this.selectDropdowns[filterName] !== '') {
          accumulator.push({
            filterModel: filterName
          })
        }
        return accumulator
      }, [])
      return activeFilters
    },
    getQueryStringUrl () {
      if(!this.activeFilters)
        return this.getDefaultPageUrl();

      const filtersWithValues = this.activeFilters.filter((filterOption) => (filterOption.value !== undefined))

      //make the query string prettier by not including page if not necessary
      const pageString = this.page ? '&page=' + this.page : '';

      return (filtersWithValues.length > 0)
        ? '?' + convertFiltersToString(filtersWithValues).join('&') + pageString
        : this.getDefaultPageUrl()
    },
    apiUrl () {
      return [
        window.location.origin,
        apiEndpoint,
        this.getQueryStringUrl
      ].join('')
    },
    hasMoreResultsToLoad () {
      return (this.searchResults.length < this.totalResults)
    }
  },
  methods: {
    getDefaultPageUrl () {
      return window.location.pathname.slice(1)
    },
    checkAnyAppliedFilter (filterNode) {
      return filterNode.value !== undefined
    },
    isThisFilterApplied (filterName) {
      return this.activeFilters.filter((filterNode) => {
        return (filterNode.key === filterName)
      })[0].value !== undefined
    },
    getFilterLabel (filterName) {
      return this.activeFilters.filter((filterNode) => {
        return (filterNode.key === filterName)
      })[0].value
    },
    getFilter (filterName) {
      return this.activeFilters.filter((filterNode) => {
        return (filterNode.key === filterName)
      })[0]
    },
    setPopulatedDropdownsOnRender () {
      if (this.getAppliedDropdowns) {
        this.getAppliedDropdowns.map((value) => {
          this.setActiveDropdown(value.filterModel, false)
        })
      }
    },
    setKeywordSearch () {
      if (this.keywordSearchModel.length > 0) {

        //keyword always clears other filters in all cases
        this.resetOtherFiltersForNewQuery()

        this.setFilterState({
          'key': 'keyword',
          'value': this.keywordSearchModel
        })

        this.keywordSearchModel = ''
        this.performSearchQuery()
      }
    },
    setActiveDropdown (modelName, performSearchAfterChange) {
      if (this.selectDropdowns[modelName] === undefined) {
        console.log('Invalid Slug name')
        return
      }
      this.setFilterState({
        'key': modelName,
        'value': this.selectDropdowns[modelName]
      }, !performSearchAfterChange) //if not triggering a search, dont reset the page either


      if(performSearchAfterChange) {
        this.performSearchQuery()
      }
    },
    clearFilter (filterName, clearAllOptions = false) {
      this.page = 0;
      
      this.activeFilters = this.activeFilters.map((filterNode) => {
        return (filterNode.key === filterName)
          ? {'key': filterNode.key, 'value': undefined}
          : filterNode
      })

      if (!clearAllOptions) {
        (this.hasFiltersApplied)
          ? this.performSearchQuery()
          : this.resetSearchResults()
      }
    },
    clearDropdownModel (filterName) {
      this.selectDropdowns[filterName] = ''
    },
    clearDropdownFilter (filterModel, clearByAllFilters = false) {
      this.clearFilter(filterModel, clearByAllFilters)
      this.clearDropdownModel(filterModel)
    },
    clearAllFilters () {
      this.clearActiveFilters()

      if (this.hasDropdownsFilters) { this.clearDropdownFilters() }
      this.resetSearchResults()
    },
    clearActiveFilters () {
      let clearAllOptions = true
      this.activeFilters.map((filterOption) => {
        this.clearFilter(filterOption.key, clearAllOptions)
      })
    },
    clearDropdownFilters () {
      let clearByAllFilters = true
      this.getArrayofDropdownModels.map((option) => { this.clearDropdownFilter(option, clearByAllFilters) })
    },
    resetSearchResults () {
      setTimeout(() => {
        this.page = 0
        this.searchResults = []
        this.pushNewWindowLocation()
        this.replaceWindowState()
      }, transitionTime)
    },
    resetOtherFiltersForNewQuery () {
      //for keyword and other filters that clear the other filters before searching
      this.clearActiveFilters()
      if(this.hasDropdownsFilters) { this.clearDropdownFilters() }
    },
    getDropdownFilterLabel (modelName) {

      //check if its a special filter (like marquee story) with a manual prettyName
      if(this.$refs[modelName + '-prettyName']){
        return this.$refs[modelName + '-prettyName'].innerHTML;
      }

      let dropdown = this.$refs[modelName + '-dropdown']

      //this is done to handle a very specific use case per AM-12517
      if(dropdown && (this.selectDropdowns[modelName] === '462010fd-8d8e-4749-8f7b-60e1a4f861f2|1c3f9bfb-d540-4cb9-b49e-d95939d876a8'
        || this.selectDropdowns[modelName] === '1c3f9bfb-d540-4cb9-b49e-d95939d876a8|462010fd-8d8e-4749-8f7b-60e1a4f861f2')){
        return 'Los Angeles - All';
      }

      return (dropdown && this.selectDropdowns[modelName] !== '' )
        ? (dropdown.selectedIndex !== -1 && dropdown.selectedIndex !== undefined)
          ? dropdown.options[dropdown.selectedIndex].innerHTML
          : false
        : false
    },
    setFilterState (kvp, doNotResetPage) {
      //for initial page load, keep the current page when setting all the filters.
      //otherwise, any time we change a filter the page should return to 0
      if(!doNotResetPage) {
        this.page=0;
      }

      this.activeFilters = this.activeFilters.map((filterNode) => {
        return (filterNode.key === kvp.key)
          ? {'key': kvp.key, 'value': kvp.value}
          : filterNode
      })
    },
    shouldPageScroll () {
      let scrollPosition = window.pageYOffset
      let searchUpdatesOffset = document.getElementById('search-updates')
        .getBoundingClientRect()
        .top + scrollPosition

      return (scrollPosition < searchUpdatesOffset)
    },
    scrollToUpdates () {

      if (this.shouldPageScroll()) {
        //settimeout seems to make the autoscroll behave better
        setTimeout(function() {
          TweenLite.to(window, scrollConfig.duration, {
            scrollTo: {
              y: `#${scrollConfig.locationID}`,
              // offsetY: scrollConfig.offset,
              autoKill: false
            }
          })
        }, 100)
      }
    },
    getCachedState () {
      return this.$data;
    },
    handleWindowPopstate ({state}) {
      if (state !== undefined && state.activeFilters.length > 0) {
        //iterate through all data properties and set them all
        Object.assign(this.$data, state)
      }
    },
    setupPopstateEvent () {
      // this event fires on pageload in firefox and safari
      window.addEventListener('popstate', this.handleWindowPopstate)
    },
    replaceWindowState () {
      window.history.replaceState(this.getCachedState(), null, this.getQueryStringUrl)
    },
    pushNewWindowLocation () {
      window.history.pushState(this.getCachedState(), null, this.getQueryStringUrl)
    },

    performSearchQuery (isInitial) {
        this.isSearchExecuted = false;
        //keep loadAll out of query string (visible to user)
        const loadAllString = this.loadAllResultsUpToPage ? '&loadAll=' + this.loadAllResultsUpToPage : '';
        axios.post(this.apiUrl + loadAllString)
          .then(isInitial? this.onInitialSearchSuccess : this.onSearchSuccess)
          .catch(this.onSearchError)
    },

    //concatenate results
    performLoadMore () {
      axios.post(this.apiUrl)
        .then(this.onLoadMoreSuccess)
        .catch(this.onSearchError)
    },

    loadMore () {
      this.page++;
      this.performLoadMore();
    },
    
    //don't concatenate results, replace instead of push history entry 
    onInitialSearchSuccess (response) {
      this.searchResults = response.data.Results
      this.totalResults = response.data.TotalSearchResults
      this.isSearchExecuted = true;
      //if this is the first search might need to remove the class that hides the regular panel
      this.removeInitialClass();
      this.replaceWindowState(); 
      this.scrollToUpdates()
    },

    //dont concatenate results
    onSearchSuccess (response) {
      this.searchResults = response.data.Results
      this.totalResults = response.data.TotalSearchResults
      this.isSearchExecuted = true;
      //if this is the first search might need to remove the class that hides the regular panel
      this.removeInitialClass();
      this.pushNewWindowLocation()
      this.scrollToUpdates()
    },
    
    //concatenate results
    onLoadMoreSuccess (response) {
      this.searchResults = this.searchResults.concat(response.data.Results)
      this.totalResults = response.data.TotalSearchResults
      // this.isSearchExecuted = true;
      this.pushNewWindowLocation()
      this.scrollToUpdates()
      
      if(this.hasMoreResultsToLoad){
        window.addEventListener('scroll', this.handleScroll);
      }
    },
    handleScroll(event){
      let reloadPoint = document.getElementsByClassName("search-results__list")[0];
      if(reloadPoint == null && document.getElementsByClassName('search-results__grid').length > 0){
        reloadPoint = document.getElementsByClassName('search-results__grid')[0];
      } 
      else if(reloadPoint == null && document.getElementsByClassName('search-results').length > 0){
        reloadPoint = document.getElementsByClassName('search-results')[0];
      }
      if(reloadPoint){
        if(this.hasMoreResultsToLoad){
          if(event.currentTarget.scrollY > (reloadPoint.lastElementChild.offsetTop - 900) || event.currentTarget.pageYOffset > (reloadPoint.lastElementChild.offsetTop - 1100)){
            this.loadMoreScroll();
            window.removeEventListener('scroll', this.handleScroll);
          }
        }
      }
    },
    performLoadMoreScroll () {
      axios.post(this.apiUrl)
        .then(this.onScrollLoadMoreSuccess)
        .catch(this.onSearchError)
    },

    loadMoreScroll () {
      this.page++;
      this.performLoadMoreScroll();
    },
    onScrollLoadMoreSuccess (response) {
      this.searchResults = this.searchResults.concat(response.data.Results)
      this.totalResults = response.data.TotalSearchResults
      this.pushNewWindowLocation()
      setTimeout(() =>{
        if(this.hasMoreResultsToLoad){
          window.addEventListener('scroll', this.handleScroll);
        }
      }, 250);
    },
    onSearchError (response) {
      console.log(response)
    },

    //help with the glitching of the cards / initial page elements when a page loads then searches
    removeInitialClass(){
      var elements = document.getElementsByClassName('hide-elements-initial--trigger')
      for(var i=0; i<elements.length; i++) {
        elements[i].classList.remove('hide-elements-initial--trigger')
      }
    }
  }
})
