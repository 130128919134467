import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import resizeMixin from './../mixins/window-resize.vue'
import {TweenLite} from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

const Hammer = require('hammerjs')

var pageConfig = {
  animationSlideTiming: 2000,
  animationDetailTiming: 1000
}

var scrollConfig = {
  duration: 1
}

Vue.component('Showcase', {
  mixins: [resizeMixin],
  props: ['backButtonCollapse', 'performanceGridCollapse', 'videoData'],
  data: () => ({
    initShowcase: false,
    isAnimating: false,
    imagePanState: false,
    isImageHovered: false,
    headlineTransitionOut: false,
    // isVerticalView: false, //moved to vuex
    fauxPosition: 0,
    touchManager: undefined
  }),
  mounted () {
    this.updateShowcaseSlide(this.activeSlideIndex, true)

    // Add class to fade in content
    window.setTimeout(() => {
      this.initShowcase = true
      this.imagePanState = true
    }, 500)

    this.attachEvents()
    this.windowResizeCallbackEvent()

    this.setupTouchEvents()
  },
  computed: {
    ...mapGetters({
      showcaseDetailViewExpanded: 'showcase/showcaseDetailViewExpanded',
      performanceGridTransitionIn: 'showcase/performanceGridTransitionIn',
      performanceGridTransitionOut: 'showcase/performanceGridTransitionOut',
      isShowcaseDisabled: 'showcase/isShowcaseDisabled',
      slides: 'showcase/slides',
      activeSlideIndex: 'showcase/activeSlideIndex',
      isVerticalView: 'showcase/isVerticalView'
    }),
    performanceGridEnabled () {
      return this.performanceGridTransitionIn
    },
    slidesLength () {
      return this.slides.length
    }
  },
  watch: {
    fauxPosition (newVal, oldVal) {
      if (newVal === this.slidesLength) {
        return
      }

      this.updateShowcaseSlide(oldVal, false)
      this.headlineTransitionOut = true

      setTimeout(() => {
        this.updateShowcaseSlide(newVal, true)

        this.headlineTransitionOut = false
      }, pageConfig.animationDetailTiming / 2)

      setTimeout(() => {
        this.isAnimating = false
      }, pageConfig.animationDetailTiming)
    },
    backButtonCollapse (isCollapsedView) {
      if (isCollapsedView) {
        this.collapseShowcaseDetailView()
      }
    },
    performanceGridCollapse (isCollapsedView) {
      if (isCollapsedView) {
        this.collapsePerformanceGrid()
      }
    }
  },
  methods: {
    ...mapMutations({
      setPerformanceGridTransitionIn: 'showcase/setPerformanceGridTransitionIn',
      setPerformanceGridTransitionOut: 'showcase/setPerformanceGridTransitionOut',
      setActiveIndex: 'showcase/setActiveIndex',
      setShowcaseSlideActiveState: 'showcase/setShowcaseSlideActiveState',
      setShowcaseDetailView: 'showcase/setShowcaseDetailView',
      setIsShowcaseDisabled: 'showcase/setIsShowcaseDisabled',
      setShowcaseDetailContent: 'showcase/setShowcaseDetailContent',
      setIsVerticalView: 'showcase/setIsVerticalView'
    }),
    windowResizeCallbackEvent () {
      (window.innerWidth >= 1000)
        ? this.setDesktopView()
        : this.setMobileView()
    },
    setDesktopView () {
      // this.isVerticalView = false
      this.setIsVerticalView({update: false})
    },
    setMobileView () {
      // this.isVerticalView = true
      this.setIsVerticalView({update: true})

      if (this.showcaseDetailViewExpanded) {
        this.collapseShowcaseDetailView()
      }
    },
    isActiveSlide (givenIndex) {
      return givenIndex === this.activeSlideIndex
    },
    setSlideIndex (givenIndex) {
      this.fauxPosition = givenIndex
    },
    updateShowcaseSlide (givenIndex, state) {
      this.setShowcaseSlideActiveState({
        index: givenIndex,
        update: state
      })

      // Update active index
      if (state) {
        this.setActiveIndex({newIndex: givenIndex})

        this.$nextTick(() => this.setShowcaseDetailContent({index: givenIndex}))
      }
    },
    clickHeadline (url) {
      if (this.isVerticalView) {
        window.location = url
      } else {
        this.expandShowcaseDetailView()
      }
    },
    expandShowcaseDetailView () {
      this.setShowcaseDetailView({update: true})
      this.setIsShowcaseDisabled({update: true})
    },
    collapseShowcaseDetailView () {
      this.setShowcaseDetailView({update: false})

      setTimeout(() => {
        this.setIsShowcaseDisabled({update: false})
      }, pageConfig.animationDetailTiming)
    },
    // Desktop only - is available to be scrolled down on mobile
    togglePerformanceGrid () {
      if (this.isVerticalView) {
        this.expandPerformanceGrid()
      } else {
        (this.performanceGridEnabled)
          ? this.collapsePerformanceGrid()
          : this.expandPerformanceGrid()
      }
    },
    collapsePerformanceGrid () {
      this.setPerformanceGridTransitionIn({update: false})
      this.setPerformanceGridTransitionOut({update: true})
      this.setIsShowcaseDisabled({update: false})

      setTimeout(() => {
        this.setPerformanceGridTransitionOut({update: false})
        this.isAnimating = false
      }, pageConfig.animationDetailTiming)

      if (this.isVerticalView) {
        this.scrollToTop()
      }
    },
    expandPerformanceGrid () {
      this.setPerformanceGridTransitionIn({update: true})

      setTimeout(() => {
        this.setIsShowcaseDisabled({update: true})
      }, pageConfig.animationDetailTiming)
    },
    scrollToTop () {
      TweenLite.to(window, scrollConfig.duration, { scrollTo: { y: 0, autoKill: false } })
    },
    attachEvents () {
      this.setMouseWheelEvent()
    },
    setMouseWheelEvent () {
      window.addEventListener('wheel', (event) => {
        if (!this.isAnimating && !this.isShowcaseDisabled) {
          this.handleScroll(event)
        }
      })
    },
    handleScroll ({deltaY}) {
      if (this.isAnimating || this.isShowcaseDisabled || this.isVerticalView) {
        return
      }

      (deltaY > 0)
        ? this.moveUpPanel()
        : this.moveDownPanel()

      this.isAnimating = true
    },
    moveUpPanel () {
      this.fauxPosition = (this.fauxPosition + 1) % this.slidesLength
    },
    moveDownPanel () {
      this.fauxPosition = (this.fauxPosition - 1 < 0)
        ? this.slidesLength - 1
        : this.fauxPosition - 1
    },
    handleSwipe (swipeDirection) {
      if (this.isAnimating || this.isShowcaseDisabled) {
        return
      }

      if (swipeDirection === 'swipeUp') {
        this.moveUpPanel()
      } else if (swipeDirection === 'swipeDown') {
        // scroll up
        this.moveDownPanel()
      }

      this.isAnimating = true
    },
    setupTouchEvents () {
      this.touchManager = new Hammer.Manager(this.$refs.showcase, {
        recognizers: [[Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }]]
      })

      this.touchManager.on('swiperight', (ev) => this.handleSwipe('swipeDown'))
      this.touchManager.on('swipeleft', (ev) => this.handleSwipe('swipeUp'))
    },
    handleRightArrowClick () {
      this.moveUpPanel()
    },
    handleLeftArrowClick () {
      this.moveDownPanel()
    },
    // Close the performance grid popout view when the left gutter is clicked
    handleBackgroundClick () {
      if (this.performanceGridTransitionIn && !this.isVerticalView && !this.isAnimating) {
        console.log('close from background click')
        this.collapsePerformanceGrid()
      }
    }
  }
})
