//attempt to solve the initial state in HTML problem 
//https://github.com/vuejs/vue/issues/2597

// copy your class in CSS (can't use the same class that is v-bound)
// then this will remove it at the same time v-bind adds the other one 
// preventing the moment where the class is missing

import Vue from 'vue';
Vue.directive('RemoveInitialClass', (el, binding, vnode) => {

    if(el.classList.contains(binding.value)) {
      el.classList.remove(binding.value);
    }
})