import Vue from 'vue'

export default {
    data: function () {
        return {
            showAudioElement: false
        }
    },
    created () {
    
    },
    methods: {

        playPodcast() {
            this.showAudioElement = true;
            this.$refs.podcast.load();
            this.$refs.podcast.play();
          }
    }

}