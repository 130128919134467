import Vue from 'vue'

Vue.component('PhotoGallery', {
  props: ['slides'],
  data: function () {
    return {
      initExplorer: false,
      engageButtonHover: false,
      activeIndex: 0
    }
  },
  methods: {
    toggleDropdown () {
      this.isExpanded = !this.isExpanded
    },
    collapseDropdown () {
      this.isExpanded = false
    },
    engageWithCarousel () {
      this.initExplorer = true
    },
    mouseOverEvent () {
      this.engageButtonHover = true
    },
    mouseLeaveEvent () {
      this.engageButtonHover = false
    },
    moveToPrevious () {
      (this.activeIndex === 0)
        ? this.activeIndex = this.slides.length - 1
        : --this.activeIndex
    },
    moveToNext () {
      (this.activeIndex === this.slides.length - 1)
        ? this.activeIndex = 0
        : ++this.activeIndex
    }
  }
})
