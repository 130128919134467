let setupJSON = (propName) =>
  (window.json && window.json.showcase && window.json.showcase[propName])
    ? window.json.showcase[propName]
    : undefined

export default {
  namespaced: true,
  state: {
    // data and position
    slides: setupJSON('slides'),
    activeSlideIndex: 0,
    activeSlideContent: {},
    // State of component
    isAnimating: false,
    showcaseDetailViewExpanded: false,
    performanceGridTransitionIn: false,
    performanceGridTransitionOut: false,
    isShowcaseDisabled: false,
    isVerticalView: false,
  },
  getters: {
    activeSlideIndex: state => state.activeSlideIndex,
    activeSlideContent: state => state.activeSlideContent,
    isAnimating: state => state.isAnimating,
    slides: state => state.slides,
    showcaseDetailViewExpanded: state => state.showcaseDetailViewExpanded,
    performanceGridTransitionIn: state => state.performanceGridTransitionIn,
    performanceGridTransitionOut: state => state.performanceGridTransitionOut,
    isShowcaseDisabled: state => state.isShowcaseDisabled,
    isVerticalView: state => state.isVerticalView
  },
  mutations: {
    setActiveIndex (state, payload) {
      state.activeSlideIndex = payload.newIndex
    },
    setAnimationState (state, payload) {
      state.isAnimating = payload.update
    },
    setShowcaseDetailView (state, payload) {
      state.showcaseDetailViewExpanded = payload.update
    },
    setPerformanceGridTransitionIn (state, payload) {
      state.performanceGridTransitionIn = payload.update
    },
    setPerformanceGridTransitionOut (state, payload) {
      state.performanceGridTransitionOut = payload.update
    },
    setIsShowcaseDisabled (state, payload) {
      state.isShowcaseDisabled = payload.update
    },
    setShowcaseSlideActiveState (state, payload) {
      state.slides[payload.index].isActive = payload.update
    },
    setShowcaseDetailContent (state, payload) {
      state.activeSlideContent = state.slides[payload.index].detailContent
    },
    setShowcaseDetailContentForMarqueeStoryDetail (state, payload) {
      state.activeSlideContent = payload
    },
    setIsVerticalView (state, payload) {
      state.isVerticalView = payload.update
    }
  }
}
