import Vue from 'vue'
import {TweenLite} from 'gsap'

//collapsible sections were shared across the app namespace in the template site
//and initialized with json data in the window object
//removed window.json dependency but kept the global namespace, so every section needs its own unique ID (sectionLabel)
// see related insights, related-tabbed-content, etc

//this is needed for any page app that has sections that turn into accordions on mobile, or any components that turn into accordions on mobile

const constructTweenObject = (givenObj) =>
  Object.keys(givenObj)
    .reduce((newObj, key) => {
      newObj[key] = {
        isExpanded: givenObj[key]
      }
      return newObj
    }, {})


export default {
  data: function () {
    return {
      collapsibleSections: {}
    }
  },
  methods: {
    // expandable accordions, sections
    isAccordionExpanded (sectionLabel) {

      //set initial data to remove window.json dependency
      //https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats !! 
      if(!this.collapsibleSections[sectionLabel])
        this.$set(this.collapsibleSections, sectionLabel, {isExpanded: false})

      return this.collapsibleSections[sectionLabel].isExpanded === true
    },
    toggleMobileAccordion (sectionToExpand) {
      if (window.innerWidth > 767) {
        return
      }

      if (this.collapsibleSections[sectionToExpand].isExpanded) {
        this.collapsibleSections[sectionToExpand].isExpanded = false
        this.collapseAccordion(this.$refs[sectionToExpand])
      } else {
        this.collapsibleSections[sectionToExpand].isExpanded = true
        this.expandAccordion(this.$refs[sectionToExpand])
      }
    },
    expandAccordion (DOMRef) {
      var navToggleHeight = DOMRef.querySelector('.tabby-area__nav-toggle') != null ? DOMRef.querySelector('.tabby-area__nav-toggle').offsetHeight : 0;
      var navOptionsHeight = DOMRef.querySelector('.tabby-area__nav-options') != null ? DOMRef.querySelector('.tabby-area__nav-options').offsetHeight : 0;
      var calcHeight = navToggleHeight + navOptionsHeight;
      var contentHeight = DOMRef.querySelector('.tabby-area') != null ? DOMRef.querySelector('.tabby-area').offsetHeight + 16 : 0;
      var finalHeight = contentHeight > calcHeight ? contentHeight : calcHeight

      TweenLite.set(DOMRef, {height: finalHeight == 0 ? 'auto' : finalHeight})
      TweenLite.from(DOMRef, 0.35, {height: 0, ease: 'Power1.easeIn'})
      TweenLite.fromTo(DOMRef, 0.35, {opacity: 0}, {opacity: 1, ease: 'Power1.easeIn', delay: 0.35})
    },
    collapseAccordion (DOMRef) {
      TweenLite.to(DOMRef, 0.35, {opacity: 0, ease: 'Power1.easeIn'})
      TweenLite
        .to(DOMRef, 0.35, {height: 0, ease: 'Power1.easeIn', delay: 0.35})
        .eventCallback('onComplete', function () {
          TweenLite.set(DOMRef, {clearProps: 'all'})
        })
    }
  }
}
