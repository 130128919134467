import Vue from 'vue'
import { TweenLite } from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

export default function () {
  if (!document.getElementById('back-to-top')) {
    return false
  }

  new Vue({
    el: '#back-to-top',
    data: {
        visible: false,
    },
    mounted() {
        this.handleUserScroll();
    },
    computed: {
    },
    methods: {
        handleUserScroll () {
            var clientHeight = document.documentElement.clientHeight;
            var scrollHeight = document.documentElement.scrollHeight;
            var scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;
            
            this.visible = clientHeight === scrollHeight ? false : scrollTop > (clientHeight * 0.25);
        },
        scrollToTop () {
            setTimeout(function() {
                TweenLite.to(window, 1, {
                    scrollTo: {
                        y: 0,
                        autoKill: false
                    }
                })
            }, 100);
        },
    }
  })
}
