import Vue from 'vue'
import { TweenLite } from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

var scrollConfig = {
  duration: 1,
  offset: 85
}

Vue.component('JumptoNav', {
  data: () => ({
    isHovered: false,
    isExpanded: false
  }),
  methods: {
    jumpToSection (locationId) {
      TweenLite.to(window, scrollConfig.duration, {
        scrollTo: {
          y: `#${locationId}`,
          // offsetY: scrollConfig.offset,
          autoKill: false
        }
      })
      this.isExpanded = false
      this.removeEventForClickingOutside()
    },
    toggleDropdown () {
      this.isExpanded = !this.isExpanded

      this.isExpanded
        ? this.addEventForClickingOutside()
        : this.removeEventForClickingOutside()
    },
    handleClickEvent (event) {
      var isClickInside = this.$el.contains(event.target)

      // The click was outside the specifiedElement, do something
      if (!isClickInside) {
        this.toggleDropdown()
      }
    },
    addEventForClickingOutside () {
      document.addEventListener('click', this.handleClickEvent, false)
    },
    removeEventForClickingOutside () {
      document.removeEventListener('click', this.handleClickEvent, false)
    }
  }
})
