import Vue from 'vue'
import collapsibleSections from './../mixins/collapsibleSections.vue'

export default function () {
  if (!document.getElementById('topic-landing-app')) {
    return false
  }

  new Vue({
    el: '#topic-landing-app',
    mixins: [collapsibleSections]
  })
}
