import Vue from 'vue'
import { TimelineLite } from 'gsap'

Vue.component('LandingShowcaseNav', {
  props: {
    navigationLength: Number
  },
  data: function () {
    return {
      activeNode: -1,
      componentHeight: 'auto',
      timeline: undefined,
      timelineHeading: undefined,
      showChildrenClass: false,
      isVerticalView: false
    }
  },
  watch: {
    activeNode (newVal, oldVal) {
      (newVal > -1)
        ? this.showChildrenAnimation(newVal)
        : this.collapseChildrenAnimation(oldVal)
    },
    areAnyChildrenExpanded () {
      return this.activeNode > -1
    }
  },
  mounted () {
    this.windowResizeCallbackEvent()
  },
  computed: {
    slideLength () {
      return this.navigationLength - 1
    },
    emptyArrayLength () {
      return Array.apply(null, Array(this.navigationLength))
    }
  },
  methods: {
    windowResizeCallbackEvent () {
      (window.innerWidth >= 1000)
        ? this.isVerticalView = false
        : this.isVerticalView = true
    },
    expandChildren (nodeOption) {
      this.activeNode = nodeOption
    },
    collapseChildren () {
      this.activeNode = -1
    },
    isActiveItem (nodeOption) {
      return (nodeOption === this.activeNode)
    },
    showChildrenAnimation (indexToShow) {
      let { navWrapper } = this.$refs

      this.componentHeight = navWrapper.clientHeight

      let childrenNodes = this.emptyArrayLength.map((accu, index) => {
        return (indexToShow === index)
          ? accu
          : this.$refs[`navOption${index}`]
      }).filter((item) => item)

      this.animateOutChildren(childrenNodes)
    },
    handler (e){
      e.stopPropagation();
      e.preventDefault();
    },
    animateOutChildren (nodes) {
      this.timeline = new TimelineLite()
      document.addEventListener("click", this.handler, true)
      this.timeline.staggerFromTo([...nodes], 0.5,
        { opacity: 1, x: 0, timeScale: 0.75 },
        { opacity: 0, x: 15, ease: 'Power1.easeOut', timeScale: 1 },
        0.15)
        .eventCallback('onComplete', this.completeOutFunction)
        .eventCallback('onReverseComplete', this.reverseCompleteListing)
    },
    completeOutFunction () {
      let listDomNode = this.$refs[`navOption${this.activeNode}`]
      let positionForHeadingToMove = this.positionForHeadingToMove(listDomNode)
      this.showChildrenClass = true
      if (positionForHeadingToMove > 0) {
        this.timelineHeading = new TimelineLite()
        this.timelineHeading
          .to(listDomNode, 1, { y: positionForHeadingToMove * -1 })
          .eventCallback('onReverseComplete', this.reverseCompleteHeading)
      }
      document.removeEventListener("click", this.handler, true)
    },
    positionForHeadingToMove (nodeToMove) {
      return nodeToMove.offsetTop
    },
    collapseChildrenAnimation (nodeToCollapse) {
      this.showChildrenClass = false
      document.addEventListener("click", this.handler, true)
      if (this.timelineHeading !== undefined) {
        this.timelineHeading.reverse()
      } else {
        this.reverseCompleteHeading()
      }
    },
    reverseCompleteHeading () {
      document.addEventListener("click", this.handler, true)
      this.timelineHeading = undefined
      this.timeline.reverse()
    },
    reverseCompleteListing () {
      document.removeEventListener("click", this.handler, true)
      this.timeline = undefined
    },
    resizeCheck () {
      if ( this.isVerticalView && (window.innerWidth >= 1000)) {
        this.expandChildren()
        this.isVerticalView = false
      }

      if ( !this.isVerticalView && (window.innerWidth < 1000)) {
        this.expandChildren()
        this.isVerticalView = true
      }
    }
 },
  created: function () {
    window.addEventListener('resize', this.resizeCheck);
  },
  destroyed: function () {
    window.removeEventListener('resize', this.resizeCheck);
  }
})
