import Vue from 'vue'
import resizeMixin from './../mixins/window-resize.vue'
import { mapGetters } from 'vuex'


Vue.component('team-carousel', {
  mixins: [resizeMixin],
  props: {
    viewallUrl: {
      type: String,
      default: '#0'
    },
    cardItems: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      viewallURL: '',
      currentIndex: 0,
      beltStyles: {},
      slidesPerViewport: 3
    }
  },
  watch: {
    cardItems (newVal, oldVal) {
      if (newVal.length > 0) {
        this.resetCarousel()
      }
    }
  },
  computed: {
    ...mapGetters({
      activeSlideContent: 'showcase/activeSlideContent',
    }),
    cardItemsLength () {
      return this.cardItems.length
    },
    orphanChildren () {
      return this.cardItemsLength % this.slidesPerViewport
    },
    carouselLength () {
      return Math.ceil(this.cardItemsLength / this.slidesPerViewport)
    },
    displayCarouselArrows (){
        return this.cardItemsLength > this.slidesPerViewport;
    },
    startOfCarousel () {
      return this.currentIndex === 0
    },
    endOfCarousel () {
      return this.currentIndex === this.carouselLength - 1
    },
    cardWidth () {
      if (this.slidesPerViewport) {
        return this.$refs.domBelt.children[0].clientWidth
      }
    }
  },
  mounted: function () {
    this.windowResizeCallbackEvent()
  },
  methods: {
    windowResizeCallbackEvent () {
      (window.innerWidth >= 1200)
        ? this.slidesPerViewport = 3
        : (window.innerWidth >= 600)
          ? this.slidesPerViewport = 2
          : this.slidesPerViewport = 1
    },
    nextSlide () {
      let cardIndex = this.currentIndex

      ++cardIndex;

      (cardIndex >= this.carouselLength)
        ? this.currentIndex = this.carouselLength - 1
        : this.currentIndex = cardIndex

      this.updateBeltStyles()
    },
    previousSlide () {
      let cardIndex = this.currentIndex

      --cardIndex;

      (cardIndex < 0)
        ? this.currentIndex = 0
        : this.currentIndex = cardIndex

      this.updateBeltStyles()
    },
    updateBeltStyles () {
      let carouselOffset

      carouselOffset = this.currentIndex * this.cardWidth * this.slidesPerViewport

      if(this.carouselLength === 1){
        //not sure why this case was happening, but it was getting weird values sometimes for carousels with only one item
        carouselOffset = 0;
      }
      else if (this.currentIndex === this.carouselLength - 1) {
        if (this.orphanChildren > 0) {
          carouselOffset = (this.currentIndex) * this.cardWidth * this.slidesPerViewport - ((this.slidesPerViewport - this.orphanChildren) * this.cardWidth)
        } else {
          carouselOffset = (this.currentIndex) * this.cardWidth * this.slidesPerViewport
        }
      } else {
        carouselOffset = this.currentIndex * this.cardWidth * this.slidesPerViewport
      }
      this.beltStyles = {
        transform: 'translateX(' + (carouselOffset * -1) + 'px)'
      }
    },
    resetCarousel () {
      this.currentIndex = 0
      this.updateBeltStyles()
    }
  }
})
