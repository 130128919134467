import Vue from 'vue'
import axios from 'axios'
import searchLanding from './../mixins/searchLanding.vue'
import siteSearchTypeahead from './../mixins/siteSearchTypeahead.vue'


const config = {
  apiEndpoint: '/api/sitecore/SiteSearchApi/Search',
  transitionTime: 1000
}

export default function () {
  if (!document.getElementById('site-search-app')) {
    return false
  }

  new Vue({ // eslint-disable-line
    el: '#site-search-app',
    mixins: [searchLanding(config), siteSearchTypeahead],
    data: {
      totalResults : 0,
      showSearchResults: false,
      //keywordSearchModel, page, filters, searchresults set from searchlanding mixin
    },
    created () {

    },
    mounted () {
      document.addEventListener("click", this.resetTypeahead)
      this.$refs.typeaheadsafezone.addEventListener("click", function(e){
        e.stopPropagation();
      })
      
      var that = this.resetTypeahead;
      document.onkeydown = function(evt) {
        evt = evt || window.event; 
        //escape key
        if(evt.keyCode == 27){
          that();
        }
      }
    },
    computed: {
      activeSearchTerm(){
        return this.getFilter('keyword').value
      },

    },
    methods: {
      //override mixin 
      shouldPageScroll(){
        return false;
      },

      //override mixin
      setKeywordSearch () {
        if (this.keywordSearchModel.length > 0) {
  
          //keyword always clears other filters in all cases
          this.resetOtherFiltersForNewQuery()
  
          this.setFilterState({
            'key': 'keyword',
            'value': this.keywordSearchModel
          })
  
          // this.keywordSearchModel = ''
          this.performSearchQuery()
        }
      },

     updateSegment(tag, label){
        this.setFilterState({'key': 'segment', 'value': tag});

        if(this.getFilter('keyword').value)
          this.performSearchQuery();
     },

     isActiveSegment(tag){
        if(tag === '')
          return this.getFilter('segment').value == undefined;

        return this.getFilter('segment').value === tag
     },

    },
    watch: {

      //reset the typeahead when the results come in
      searchResults(newVal, oldVal){
        this.resetTypeahead()
      },

    },
  })
}
