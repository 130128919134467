import { polyfill } from 'es6-promise'
import Vue from 'vue'
import Vuex from 'vuex'

// Import vuex modules
import showcase from './modules/showcase'
import careersLanding from './modules/careers-landing'

polyfill() // initialize polyfill to fix IE bug

// Install Vuex 
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    showMenu: false,
    siteSearch: {
      expanded: false
    },
    isModalOpen: false
  },
  getters: {
    showMenu: state => state.showMenu,
    showSiteSearch: state => state.siteSearch.expanded,
    isModalOpen: state => state.isModalOpen
  },
  mutations: {
    toggleModalState(state, payload) {
      state.isModalOpen = payload == "open" ? true : false;
    },
    toggleMenu (state) {
      if (state.showMenu) {
        state.showMenu = false
      } else {
        state.showMenu = true

        if (state.siteSearch.expanded) {
          state.siteSearch.expanded = false
        }
      }
    },
    toggleSiteSearch (state) {
      if (state.siteSearch.expanded) {
        state.siteSearch.expanded = false
      } else {
        state.siteSearch.expanded = true
        //focus on site search input
        document.getElementById('site-search-focus').focus();

        if (state.showMenu) {
          state.showMenu = false
        }
      }
    }
  },
  modules: {
    showcase,
    careersLanding
  }
})

export default store
