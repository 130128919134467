import Vue from 'vue'
import vuexStore from './../store/vuex-store'
import { mapGetters } from 'vuex'

export default function () {
  if (!document.getElementById('homepage')) {
    return false
  }
  return new Vue({
    el: '#homepage-app',
    data: {
      collapseDetailView: false,
      collapsePerformanceGridView: false,
      tempForceScrollRemoval: false
    },
    mounted(){
        var self = this;
        window.addEventListener("keydown", function(e){
            
            if (self.showcaseDetailViewExpanded || !self.collapsePerformanceGridView){
                if (e.type === "keydown" && e.keyCode === 27){
                    if (self.showcaseDetailViewExpanded){
                        self.toggleShowcaseDetailView();
                    }else{
                        self.collapsePerformanceGrid();
                    }
                }
            }
        });
    },
    store: vuexStore,
    computed: {
      ...mapGetters({
        showcaseDetailViewExpanded: 'showcase/showcaseDetailViewExpanded',
        isShowcaseDisabled: 'showcase/isShowcaseDisabled',
        performanceGridTransitionIn: 'showcase/performanceGridTransitionIn',
        performanceGridTransitionOut: 'showcase/performanceGridTransitionOut',
        activeSlideContent: 'showcase/activeSlideContent',
        isVerticalView: 'showcase/isVerticalView',
        activeSlideIndex: 'showcase/activeSlideIndex',
        isModalOpen: 'isModalOpen'
    })
    },
    methods: {
      toggleShowcaseDetailView () {
        this.collapseDetailView = true
        this.$nextTick(() => { 
            this.collapseDetailView = false;
            var homePageEl = document.getElementById('homepage');

            //Added to prevent browser from snapping back after scroll is removed after animation
            // Set Class to remove scroll before animation starts
            //homePageEl.classList.add('showcase-layout--force-no-scroll');
            this.tempForceScrollRemoval = true;
            // Set a Timeout to remove class after animation
            setTimeout(this.toggleOffForceScroll, 1000);
        })
        
        },
        toggleOffForceScroll () {
            this.tempForceScrollRemoval = false;
      },
      collapsePerformanceGrid () {
        this.collapsePerformanceGridView = true
        this.$nextTick(() => { this.collapsePerformanceGridView = false })
      }
    }
  })
}
