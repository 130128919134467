import Vue from 'vue'
import jumpTo from 'jump.js'

var scrollConfig = {
  duration: 600,
  offset: -100
}

Vue.component('profile-bar', {
  props: ['activeScrollSection'],
  data: () => ({
    isFixed: false,
    stickyOffset: 0,
    activeSectionName: '',
    toggleLabel: '',
    showNav: false
  }),
  watch: {
    activeScrollSection (newValue, oldValue) {
      this.activeSectionName = newValue
    }
  },
  mounted () {
    this.calculateTopStickyOffset()

    this.$nextTick(() => {
      this.handleScroll(window.pageYOffset)
    })
  },
  methods: {
    handleScroll (windowOffset) {
      var self = this

      if (this.stickyOffset > windowOffset) {
        self.isFixed = false
      } else {
        self.isFixed = true
      }
    },
    toggleNav () {
      this.showNav = !this.showNav
    },
    calculateTopStickyOffset () {
      var overview = document.querySelector('.overview-block');
      if(overview)
        this.stickyOffset = overview.getBoundingClientRect().top + window.pageYOffset - 100
      else
        this.stickyOffset = 200;
    },
    isActiveSection (sectionID, sectionLabel) {
      if (sectionID === this.activeSectionName) this.toggleLabel = sectionLabel
      return sectionID === this.activeSectionName
    },
    jumpToSection (sectionID) {
      (sectionID === 'overview')
        ? jumpTo('main', {duration: scrollConfig.duration})
        : jumpTo('[data-jump-name="' + sectionID + '"]', scrollConfig)

      this.showNav = false
    },
  }
})

Vue.component()
