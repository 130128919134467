import Vue from 'vue'
import { TimelineLite } from 'gsap'

Vue.component('LawyerStories', {
  props: ['stories'],
  data: function () {
    return {
      initExplorer: false,
      engageButtonHover: false,
      activeIndex: 0,
      currentStory: {},
      isAnimating: false,
      timeline: undefined
    }
  },
  mounted () {
    this.updateCurrentStory()
    this.setupTimelineAnimation()
  },
  watch: {
    activeIndex (newVal, oldVal) {
      if (newVal !== undefined) this.startAnimationProcess()
    }
  },
  computed: {
    storiesLength () {
      return this.stories.length - 1
    },
    storiesClientLength () {
      return this.stories.length
    },
    currentSlideCount () {
      return this.activeIndex + 1
    }
  },
  methods: {
    updateCurrentStory () {
      this.currentStory = this.stories[this.activeIndex]
    },
    toggleDropdown () {
      this.isExpanded = !this.isExpanded
    },
    collapseDropdown () {
      this.isExpanded = false
    },
    engageWithCarousel () {
      this.initExplorer = true
    },
    mouseOverEvent () {
      this.engageButtonHover = true
    },
    mouseLeaveEvent () {
      this.engageButtonHover = false
    },
    movePreviousStory () {
      if (this.isAnimating) return

      (this.activeIndex === 0)
        ? this.activeIndex = this.storiesLength
        : --this.activeIndex
    },
    moveNextStory () {
      if (this.isAnimating) return

      (this.activeIndex === this.storiesLength)
        ? this.activeIndex = 0
        : ++this.activeIndex
    },
    setupTimelineAnimation () {
      let { heading, question, answer } = this.$refs

      this.timeline = new TimelineLite()

      this.timeline.staggerFromTo([heading, question, answer], 0.5,
        { opacity: 1, x: 0 },
        { opacity: 0, x: 10, ease: 'Power1.easeOut' },
        0.15).pause()
        .eventCallback('onComplete', this.completeAnimation)
        .eventCallback('onReverseComplete', this.reverseCompleteAnimation)
    },
    startAnimationProcess () {
      this.isAnimating = true

      this.timeline.play()
    },
    completeAnimation () {
      this.updateCurrentStory()

      this.$nextTick(() => { this.timeline.reverse() })
    },
    reverseCompleteAnimation () {
      this.isAnimating = false
    }
  }
})
