import Vue from 'vue'

Vue.component('accordion', {
  props: ['expandAccordion'],
  data: function () {
    return {
      isExpanded: false,
      collapsedHeight: ''
    }
  },
  watch: {
    expandAccordion (isOpened) {
      this.isExpanded = isOpened
    },
    isExpanded: function (expandNode) {
      const el = this.$refs.bodyContent
      const sectionHeight = el.scrollHeight;

      (expandNode)
        ? this.expandContent(sectionHeight)
        : this.collapseContent(sectionHeight)

      this.removeListener(el, expandNode)
    }
  },
  methods: {
    toggleNode () {
      this.isExpanded = !this.isExpanded
    },
    expandContent (heightToExpand) {
      window.requestAnimationFrame(() => {
        this.collapsedHeight = heightToExpand + 'px'
      })
    },
    collapseContent (currentHeight) {
      window.requestAnimationFrame(() => {
        this.collapsedHeight = currentHeight + 'px'

        window.requestAnimationFrame(() => {
          this.collapsedHeight = 0 + 'px'
        })
      })
    },
    removeListener (element, expandState) {
      var expandedState = expandState

      element.addEventListener('transitionend', () => {
        // remove this event listener so it only gets triggered once
        element.removeEventListener('transitionend', void (0))

        Vue.nextTick(() => {
          if (expandedState) {
            // remove "height" from the element's inline styles, so it can return to its initial value
            this.collapsedHeight = 'auto'
          } else {
            this.collapsedHeight = 0 + 'px'
          }
        })
      })
    }
  }
})
