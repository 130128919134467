import Vue from 'vue'

export default function () {
    if (!document.getElementById('testimonial-landing-app')) {
    return false
  }

  new Vue({
      el: '#testimonial-landing-app'
  })
}
