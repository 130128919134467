import Vue from 'vue'
import axios from 'axios'


Vue.component('related-entities', {
  props: {
    //how many to take each time
    'take': {
      type: Number,
    },
    //how many are on the page pre-ajax
    'initialTake': {
      type: Number
    },
    //total results so we know when to show load more
    'total': {
      type: Number
    },
    'endpoint': {
      type: String
      // default: '/api/sitecore/NewsInsightsApi/RelatedInsights'
    },
    //related ID for the search query
    'entity': {
      type: String
    }

  },
  data: function () {
    return {
      entityResults: [],
      skip: 0,
    }
  },
  mounted () {
    this.skip = this.initialTake;
  },
  
  computed: {
    hasMore () {
      return (this.skip < this.total)
    },

    apiUrl () {
      return [
        window.location.origin,
        this.endpoint,
        '?relatedID=',
        this.entity,
        '&skip=',
        this.skip,
        '&take=',
        this.take,
      ].join('')
    }
  },

  methods: {
    loadMore () {
      axios.post(this.apiUrl)
      .then(this.onSearchSuccess)
      .catch(this.onSearchError)
    },

    onSearchSuccess(response) {
        this.entityResults = this.entityResults.concat(response.data.Results)
      
      this.skip += this.take;
    },

    onSearchError() {
      //TODO: display friendly error?
      console.error('search error')
    },
  }
})
