// Global Components
import siteHeader from './site-header.vue'
import siteOvelay from './site-overlay.vue'

import homepage from './homepage.vue'
import marqueeStoryDetail from './marquee-story.vue'
import peopleDetail from './people-detail.vue'
import servicesDetail from './services-detail.vue'
import peopleSearch from './people-search.vue'
import staffSearch from './staff-search.vue'

import siteSearchPage from './site-search-page.vue'
import insightsSearch from './insights-search.vue'
import servicesLanding from './services-landing.vue'
import articlesDetail from './articles-detail.vue'
import locationsDetail from './locations-detail.vue'
import locationsLanding from './locations-landing.vue'
import careersDetail from './careers-detail.vue'
import careersLanding from './careers-landing.vue'
import generalContent from './general-content.vue'
import languageIndex from './language-index.vue'
import testimonialDetail from './testimonial-detail.vue'
import testimonialLanding from './testimonial-landing.vue'
import topicLanding from './topic-landing.vue'
import scrollToTop from './back-to-top.vue'

siteHeader()
siteOvelay()
homepage()
marqueeStoryDetail()
peopleDetail()
servicesDetail()
peopleSearch()
staffSearch()
siteSearchPage()
servicesLanding()
articlesDetail()
locationsDetail()
insightsSearch()
locationsLanding()
careersDetail()
careersLanding()
generalContent()
languageIndex()
testimonialDetail()
testimonialLanding()
topicLanding()
scrollToTop()
