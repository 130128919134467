import Vue from 'vue'
import vuexStore from './../store/vuex-store'

export default function () {
  if (!document.getElementById('careers-landing-app')) {
    return false
  }

  new Vue({ // eslint-disable-line
    el: '#careers-landing-app',
    store: vuexStore
  })
}
