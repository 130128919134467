import Vue from 'vue'

Vue.component('LocationCard', {
  data: function () {
    return {
      isHovered: false
    }
  },
  methods: {
    isMouseOver () {
      this.isHovered = true
    },
    isMouseOut () {
      this.isHovered = false
    }
  }
})
