import axios from 'axios'
import { TweenLite } from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import searchLanding from './../mixins/searchLanding.vue'
const transitionTime = 1000;


export default ({ apiEndpoint }) => ({
  mixins: [searchLanding({apiEndpoint, transitionTime})],
  
  data: function () {
    return {
     
    }
  },
  mounted () {
    console.log('people search using ' + apiEndpoint);
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    hasLetterFilter () {
      return this.isThisFilterApplied('letter')
    },
    activeLetterFiltered () {
      return this.activeFilters.filter(filter => {
        return filter.key === 'letter'
      })[0]
    }
  },

  methods: {
    isLetterDisabled (disabledState) {
      return disabledState === 'true'
    },
    isActiveLetter (letter) {
      return (this.activeLetterFiltered && this.activeLetterFiltered.value === letter)
    },
    setActiveLetter (letter, isDisabled) {
      if (!this.isLetterDisabled(isDisabled)) {
        //spec says setting the letter should clear the other filters 
        this.resetOtherFiltersForNewQuery()
        window.removeEventListener('scroll', this.handleScroll)
        window.addEventListener('scroll', this.handleScroll)
        this.setFilterState({
          'key': 'letter',
          'value': letter
        })

        this.performSearchQuery()
      }
    },
    
  }
})
