
// Polyfills
require('./auxillary/manifest')

// Vuex
require('./store/vuex-store')

// Directives
require('./directives/manifest')

// Components
require('./components/manifest')

// apps
require('./apps/manifest')