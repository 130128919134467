import Vue from 'vue'

//fake load more 

Vue.component('ShowcaseInsights', {
  props: {
    //use this to track
    insightCards: {
      type: Array,
      default: () => []
    },
    //OR just a total number
    totalCount: {
      type: Number
    }
  },
  data: function () {
    return {
      innerCount: 5,
    }
  },
  methods: {
    shouldShow(index) {
      return index < this.innerCount
    },
    showMore() {
      this.innerCount += 6;
    }
  },
  computed: {
    hasMore () {
      if(this.insightCards.length)
        return this.innerCount < this.insightCards.length
      else
        return this.innerCount < this.totalCount
    },
  },

  //it seems to reset fine on its own without needing to watch when insightCards changes and reset the count 
  // watch: {
  //   insightCards(idk) {
  //     console.log(idk);
  //   }
  // }
})
