import Vue from 'vue'

export default function () {
  if (!document.getElementById('locations-landing-app')) {
    return false
  }

  new Vue({ // eslint-disable-line
      el: '#locations-landing-app',
      mounted () {
          var locationsList = document.getElementById('locations-list');
          var scrollingTimeout;
          window.addEventListener('scroll', function ( event ) {
              // Clear our timeout throughout the scroll
              window.clearTimeout( scrollingTimeout );
              locationsList.classList.add("locations-prominent-listing__list-scrolling");
              // Set a timeout to run after scrolling ends
              scrollingTimeout = setTimeout(function() {
                  locationsList.classList.remove("locations-prominent-listing__list-scrolling");
              }, 500);

          }, false);
      }
  })
}
