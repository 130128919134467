import Vue from 'vue'

Vue.component('video-grid', {
  props: ['count', 'videoGridContent', 'sectionLabel', 'showingmobile', 'showingdesktop'],
  data: function () {
    return {
        showing: 4, //number of shown cards,
        isMobile: null,
        showingMobile: parseInt(this.showingmobile),
        showingDesktop: parseInt(this.showingdesktop)
    }
  },
  watch: {
    isMobile: function() {
        this.showing = this.isMobile ? this.showingMobile : this.showingDesktop; //reset shown cards on mobile state change
    }
  },    
  mounted () {
      this.checkIsMobile();
      window.addEventListener('resize', () => {
        this.checkIsMobile();
      });
  },
  methods: {
    loadMore() {
        if (this.isMobile) {
            this.showing = this.showing + this.showingMobile;
        } else {
            this.showing = this.showing + this.showingDesktop;
        }
    },
    checkIsMobile() {
        let mql = window.matchMedia('(max-width: 768px)');
        this.isMobile = mql.matches;
    }
  }
})
