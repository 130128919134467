import Vue from 'vue'
import {TweenLite} from 'gsap'
import collapsibleSections from './../mixins/collapsibleSections.vue'
// import tabbyArea from './../mixins/tabbyArea.vue'

export default function () {
  if (!document.getElementById('careers-detail-app')) {
    return false
  }

  new Vue({ // eslint-disable-line
      el: '#careers-detail-app',
      mixins: [collapsibleSections],
    data: function () {
        return {
            ...window.json
        }
    },
    methods: {
   
    }
  })
}
