import Vue from 'vue'

Vue.component('LanguageDropdown', {
  data: function () {
    return {
      isExpanded: false,
      selectedLanguage: 'English'
    }
  },
  methods: {
    collapseDropdown () {
      this.isExpanded = false
      this.removeEventForClickingOutside()
    },
    toggleDropdown () {
      this.isExpanded = !this.isExpanded

      this.isExpanded
        ? this.addEventForClickingOutside()
        : this.removeEventForClickingOutside()
    },
    handleClickEvent (event) {
      var isClickInside = this.$el.contains(event.target)

      // The click was outside the specifiedElement, do something
      if (!isClickInside) {
        this.toggleDropdown()
      }
    },
    addEventForClickingOutside () {
      document.addEventListener('click', this.handleClickEvent, false)
    },
    removeEventForClickingOutside () {
      document.removeEventListener('click', this.handleClickEvent, false)
    }
  }
})
