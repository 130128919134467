import Vue from 'vue'
import {TweenLite} from 'gsap'
import collapsibleSections from './../mixins/collapsibleSections.vue'
// import tabbyArea from './../mixins/tabbyArea.vue'

export default function () {
    if (!document.getElementById('general-content-app')) {
    return false
  }

  new Vue({ // eslint-disable-line
      el: '#general-content-app',
      mixins: [collapsibleSections],
      data: function () {
          return {
         
          }
      },
      created () {
        
      },
      watch: {
  
      },
      methods: {
         
      }
  })
}
