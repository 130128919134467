import Vue from 'vue'
import vuexStore from './../store/vuex-store'
import resizeMixin from './../mixins/window-resize.vue'
import { mapGetters } from 'vuex'
import { mapMutations } from 'vuex'

export default function () {
  if (!document.getElementById('marquee-story-app')) {
    return false
  }
  
  return new Vue({
    mixins: [resizeMixin],
    el: '#marquee-story-app',
    data: {
    },
    mounted() {
      this.setShowcaseDetailContentForMarqueeStoryDetail(window.json.activeSlideContent)
      this.windowResizeCallbackEvent()
      //setting this messes up the header classes more and it doesn't seem necessary for this view
      // this.setShowcaseDetailView({update: true})
      
    },
    store: vuexStore,
    computed: {
      ...mapGetters({
        // showcaseDetailViewExpanded: 'showcase/showcaseDetailViewExpanded',
        activeSlideContent: 'showcase/activeSlideContent',
        isVerticalView: 'showcase/isVerticalView',
      })
    },
    methods: {
      ...mapMutations({
        setShowcaseDetailContentForMarqueeStoryDetail: 'showcase/setShowcaseDetailContentForMarqueeStoryDetail',
        // setShowcaseDetailView: 'showcase/setShowcaseDetailView',
        setIsVerticalView: 'showcase/setIsVerticalView'
      }),

      windowResizeCallbackEvent () {
        (window.innerWidth >= 1000)
          ? this.setDesktopView()
          : this.setMobileView()
      },
      setDesktopView () {
        this.setIsVerticalView({update: false})
      },
      setMobileView () {
        this.setIsVerticalView({update: true})
  
        //do not collapse it on this detail view
        // if (this.showcaseDetailViewExpanded) {
        //   this.collapseShowcaseDetailView()
        // }
      },
    }
  })
}
