import Vue from 'vue'

Vue.component('CarouselHighlights', {
  data: function () {
    return {
      initExplorer: false,
      engageButtonHover: false,
      slides: (window.json && window.json.carouselHighlights) ? window.json.carouselHighlights.slides : [],
      activeIndex: 0
    }
  },
  methods: {
    getSlideCount () {
        var slideCount = this.$el.dataset.slidecount;
        if (!!slideCount && slideCount > 0){
            return slideCount;
        }else{
            return this.slides.length;
        }
    },
    toggleDropdown () {
      this.isExpanded = !this.isExpanded
    },
    collapseDropdown () {
      this.isExpanded = false
    },
    engageWithCarousel () {
      this.initExplorer = true
    },
    mouseOverEvent () {
      this.engageButtonHover = true
    },
    mouseLeaveEvent () {
      this.engageButtonHover = false
    },
    moveToPrevious () {
      (this.activeIndex === 0) 
        ? this.activeIndex = this.getSlideCount() - 1
        : --this.activeIndex 
    },
    moveToNext () {
        (this.activeIndex ===  this.getSlideCount() - 1) 
        ? this.activeIndex = 0
        : ++this.activeIndex 
    }
  }
})
