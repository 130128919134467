import Vue from 'vue'
import collapsibleSections from './../mixins/collapsibleSections.vue'
// import tabbyArea from './../mixins/tabbyArea.vue'


//changed to use tabbyarea  mixin instead of duplicated code code

// var config = {
//   animationTiming: 350 // should be in line with animation.scss file
// }


export default function () {
  if (!document.getElementById('services-detail-app')) {
    return false
  }

  new Vue({ // eslint-disable-line
    mixins: [collapsibleSections],
    el: '#services-detail-app',
    data: function () {
      return {
        
      }
    },
    
    created () {
    },
    watch: {
     
    },
    methods: {
  
    }
  })
}
