import Vue from 'vue'
import collapsibleSections from './../mixins/collapsibleSections.vue'

export default function () {
  if (!document.getElementById('locations-detail-app')) {
    return false
  }

  new Vue({
    el: '#locations-detail-app',
    mixins: [collapsibleSections]
  })
}
