import Vue from 'vue'
import MicroModal from 'micromodal'
import vuexStore from './../store/vuex-store'
import { mapGetters, mapMutations } from 'vuex'

const modalState = new CustomEvent('toggleModal');

Vue.component('video-card', {
  props: ['anchor', 'showing', 'cardData', 'modalId'],
  store: vuexStore,
  data: function () {
    return {
        shareOpen: false,
        copyUrl: undefined,
        accordionOpen: false,
        videoId: null,
    }
  },
  computed: {
    ...mapGetters({
      isModalOpen: 'isModalOpen'
    })
  },    
  watch: {
    shareOpen: function() {
      if (this.shareOpen) {
        document.addEventListener('click', this.handleShare);
      } else {
        document.removeEventListener('click', this.handleShare);
      }
    }
  },  
  mounted () {
    this.videoId = this.$el.querySelector('.video-card__iframe-container iframe') ? this.$el.querySelector('.video-card__iframe-container iframe').src : null;
    this.getCopyUrl();
  },
  methods: {
    ...mapMutations({
      toggleModalState: 'toggleModalState'      
    }),
    toggleShare() {
        this.shareOpen = !this.shareOpen;
    },
    handleShare(e) {
      let shareBox = this.$el.querySelector('.video-card__share-content');
      let isInsideClick = shareBox.contains(e.target);
      this.shareOpen = isInsideClick;
    },  
    copy() {
        let text = this.$refs.copyInput;
        text.select();
        document.execCommand("copy");
    },
    getCopyUrl() {
        this.copyUrl = `${window.location.hostname}${window.location.pathname}#${this.anchor}`;
    },
    toggleAccordion() {
        this.accordionOpen = !this.accordionOpen;
    },
    toggleModal() {
      // must pass config when using show method
      MicroModal.show(this.modalId, {
        onShow: modal => {
          modal.dataset.isOpen = true; 
          modal.dispatchEvent(modalState); 
          this.handleIframe('open'); 
          this.toggleModalState('open');
        }, 
        onClose: modal => {
          modal.dataset.isOpen = false;
          modal.dispatchEvent(modalState);
          this.handleIframe('close');
          this.toggleModalState('close');
        }, 
        openClass: 'is-open', 
        disableScroll: true, 
        disableFocus: true, 
        awaitOpenAnimation: true, 
        awaitCloseAnimation: true,
      });
    },
    handleIframe(state) {
      //sets the iframe url only when video modal is open
      let iframe = document.getElementById(this.modalId).querySelector('.modal__content iframe');
      if (state == 'open') {
        iframe.src = this.videoId;
      } else {
        iframe.src = "";
      }
    }

  }
})
