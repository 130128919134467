import Vue from 'vue'
import { mapGetters } from 'vuex'
import { mapMutations } from 'vuex'
import { TweenLite } from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

var scrollConfig = {
  duration: 0.75 // in seconds
}

Vue.component('LandingShowcase', {
    data: () => ({
        initShowcase: false
    }),
    mounted () {
        // Add class to fade in content
        this.initShowcase = true

        var self = this;
        window.addEventListener("keydown", function(e){
            if (self.showCareersBriefs){
                if (e.type === "keydown" && e.keyCode === 27){
                    self.toggleCareersBriefs();
                }
            }
        });
    },
  computed: {
    ...mapGetters({
      'showCareersBriefs': 'careersLanding/showCareersBriefs'
    })
  },
  methods: {
    ...mapMutations({
      'toggleCareersBriefs': 'careersLanding/toggleBriefsView'
    }),
    actionForCareersBriefs () {

        if (window.innerWidth < 1000) {
            this.scrollToTopPage();
        }
      //$showcase-desktop--careers
        this.toggleCareersBriefs()
    },
    scrollToTopPage () {
        TweenLite.to(window, scrollConfig.duration, { scrollTo: { y: 0, autoKill: false } })
        
    }
  }
})
