import Vue from 'vue'
import axios from 'axios'
import collapsibleSections from './../mixins/collapsibleSections.vue'
import searchLanding from './../mixins/searchLanding.vue'

const config = {
  apiEndpoint: '/api/sitecore/NewsInsightsApi/Insights',
  transitionTime: 1000
}

//originally  the content subtype filter was its own very special thing in order to handle the label + guid
//but now that all dropdown labels have been changed to pull from the markup instead of the vue state,
//this.contentSubTypeFilter could probably be removed and only the filter state used instead

export default function () {
  if (!document.getElementById('insights-search-app')) {
    return false
  }

  new Vue({ // eslint-disable-line
    el: '#insights-search-app',
    mixins: [searchLanding(config), collapsibleSections],
    computed: {
      hasProfessionalFilter () {
        return this.isThisFilterApplied('professional')
      },
      showProfessionalTypeahead() {
        return this.professionalTypeahead.length > 0;
      }
    },
    mounted () {
      //check if subtype filter needs to be set on refresh
      if(this.isThisFilterApplied('subtype')) {

        var filterValue = this.getFilter('subtype').value;
        this.contentSubTypeFilter = filterValue;
      }
    },
    created () {
      window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll);
    },
    data: {
      contentSubTypeFilter: '',
      professionalTypeahead: [],
      professionalTypeaheadName : window.json.professionalName? window.json.professionalName : '',
      keywordProfessionalsSearchModel: '',
    },
    methods: {

      //kind of have to hack the name+ guid relationship in here, making the label another model property
      //professional filter should not reset other filters, according to spec
      typeaheadSearch(guid, name){
        console.log('searching for ' + guid + ' ' + name);
        this.professionalTypeaheadName = name;
        this.setFilterState({
          'key': 'professional',
          'value': guid
        })
        this.keywordProfessionalsSearchModel = ''
        this.performSearchQuery()
      },
      setContentSubType() {
        this.setFilterState({
          'key' :  'subtype',
          'value': this.contentSubTypeFilter
        })
        this.performSearchQuery()
      },

      //only show the options when the dependent filter is selected
      //for example, only show news subtypes when news is selected in the content type  
      isValidOption(filterMatch, valueMatch){

        var filter = this.getFilter(filterMatch)

        if(filter && filter.key) {
          return filter.value === valueMatch
        }
        
        return false;
      },

      onTypeAheadSuccess(response) {
        this.professionalTypeahead = response.data.Results
      },

      onTypeAheadError (response) {
        console.error(response);
      },

      getContentSubtypeFilterLabel () {
        let dropdown = this.$refs['subtype-dropdown']
  
        return (dropdown && this.contentSubTypeFilter !== '' )
          ? (dropdown.selectedIndex !== -1 && dropdown.selectedIndex !== undefined)
            ? dropdown.options[dropdown.selectedIndex].innerHTML
            : false
          : false
      },

    },
    
    watch: {
      activeFilters(newVal, oldVal) {
        var oldContentType = oldVal.filter((filterNode) => {
          return (filterNode.key === 'type')
        })
        oldContentType = oldContentType.length? oldContentType[0].value : undefined

        var newContentType = newVal.filter((filterNode) => {
          return (filterNode.key === 'type')
        })
        newContentType = newContentType.length? newContentType[0].value : undefined


        //if content type was set and is now empty, clear the subtype
        //OR content type changes
        if((oldContentType && !newContentType) || (oldContentType !== newContentType) ){
          console.log('clearing sub type')
          this.clearFilter('subtype');
          this.contentSubTypeFilter = '';
        }


      },
      keywordProfessionalsSearchModel(newVal, oldVal) {
        //changing to 1, so that we can get hits for professionals with 2 letter last name
        if(newVal && newVal.length >= 2){
          axios.post('/api/sitecore/TypeaheadApi/ProfessionalTypeahead?keyword=' + newVal)
          .then(this.onTypeAheadSuccess)
          .catch(this.onSearchError)
        }
        else {
          this.professionalTypeahead = [];
        }
      }
    }
  })
}
