import Vue from 'vue'
import {TweenLite} from 'gsap'
import Podcast from './../mixins/podcast.vue'
import collapsibleSections from './../mixins/collapsibleSections.vue'

export default function () {
  if (!document.getElementById('articles-detail-app')) {
    return false
  }

  const constructTweenObject = (givenObj) =>
    Object.keys(givenObj)
      .reduce((newObj, key) => {
        newObj[key] = {
          isExpanded: givenObj[key]
        }
        return newObj
      }, {})


  new Vue({
    el: '#articles-detail-app',
    mixins: [Podcast, collapsibleSections ],
    
    data: function () {
      return {
      }
    },
    methods: {
      
    }
  })
}
