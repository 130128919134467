import Vue from 'vue'
import {TweenLite} from 'gsap'
import collapsibleSections from './../mixins/collapsibleSections.vue'

export default function () {
    if (!document.getElementById('language-index-app')) {
    return false
  }

  new Vue({ // eslint-disable-line
      el: '#language-index-app',
      mixins: [collapsibleSections],
      data: function () {
        return {
            
        }
      },
      created () {
      },
      watch: {

      },
      methods: {
    
      }
  })
}
