import Vue from 'vue'
import peopleSearchMixin from './../mixins/peopleSearchMixin.vue'


const config = {
  apiEndpoint: '/api/sitecore/ProfessionalsApi/Staff',
}

export default function () {
  if (!document.getElementById('staff-search-app')) {
    return false
  }

  new Vue({ // eslint-disable-line
    el: '#staff-search-app',
    mixins: [peopleSearchMixin(config)],
    computed: {
    
    },
    methods: {
     
    }
  })
}
