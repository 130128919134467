import Vue from 'vue'
import collapsibleSections from './../mixins/collapsibleSections.vue'

export default function () {
    if (!document.getElementById('testimonial-detail-app')) {
    return false
  }

  new Vue({
      el: '#testimonial-detail-app',
      mixins: [collapsibleSections]
  })
}
