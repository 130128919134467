import Vue from 'vue'
import MicroModal from 'micromodal'

const modalState = new CustomEvent('toggleModal');

Vue.component('video-modal', {
  props: ['id'],
  data: function () {
    return {
        isOpen: false
    }
  },
  template: `
  <div class="modal micromodal-slide" :id="id" aria-hidden="true">
    <div class="modal__overlay" tabindex="-1" data-micromodal-close>
        <div class="modal__container" role="dialog" aria-modal="true" aria-labelledby="modal">
        <button class="modal__close" aria-label="Close modal" v-on:click="close">
        <?xml version="1.0" encoding="UTF-8"?>
        <svg width="28px" height="27px" viewBox="0 0 28 27" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <!-- Generator: Sketch 47.1 (45422) - http://www.bohemiancoding.com/sketch -->
            <title>Close</title>
            <desc>Created with Sketch.</desc>
            <defs></defs>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
                <g id="Homepage---Vista" transform="translate(-1207.000000, -54.000000)" stroke="#ffffff" stroke-width="3">
                    <g id="Navigation---OPEN---Search-" transform="translate(-5.000000, -17.000000)">
                        <g id="Close" transform="translate(1214.000000, 73.000000)">
                            <path d="M0.686291501,0.186291501 L23.3137085,22.8137085" id="Line-2"></path>
                            <path d="M23.3137085,0.186291501 L0.686291501,22.8137085" id="Line-2"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        </button>
            <div class="modal__content">
                <slot v-bind:isOpen="isOpen"></slot>        
            </div>
        </div>
    </div>
</div>
  `,
  computed: {
  },    
  mounted () {
      this.$nextTick(() => {
          this.init();
      })
  },
  methods: {
    init() {
        let self = this;
        this.$el.addEventListener('toggleModal', (e) => {
            if (e.target.id = this.id) {
                this.isOpen = e.target.dataset.isOpen == "true" ? true : false;
            }
        });
    },
    close() {
        MicroModal.close(this.id, {
            onClose: modal => this.isOpen = false, 
        });
    }
  }
})
