export default {
  namespaced: true,
  state: {
    showCareersBriefs: false
  },
  getters: {
    showCareersBriefs: state => state.showCareersBriefs
  },
  mutations: {
    toggleBriefsView (state, payload) {
      state.showCareersBriefs = !state.showCareersBriefs
    }
  }
}
