import Vue from 'vue'

export default {
  data: function () {
    return {
      // headroom variables props
      offset: 100,
      offsetTidy: 150,
      tolerance: {
        'down': 20,
        'up': 20
      },
      enabled: true,
      isTop: false,
      isNotTop: false,
      isBottom: false,
      isNotBottom: false,
      isPinned: false,
      isUnpinned: false,
      currentScrollY: 0,
      lastKnownScrollY: 0,
      state: '',
      translate: 0,
      initialised: false // todo,
    }
  },
  mounted: function () {
    window.setTimeout(this.attachEvent, 100)
    if (window.json && window.json.headroom) {
      this.enabled = window.json.headroom.enabled
      console.log('headroom enabled: ' + this.enabled)
    }
  },
  computed: {
    headroomStyles () {
      return {
        'headroom--not-top': this.isNotTop,
        'headroom--top': this.isTop,
        'headroom--unpinned': (this.isTop) ? false : this.isUnpinned,
        'headroom--pinned': (this.isTop) ? false : this.isPinned,
        'headroom--bottom': this.isBottom,
        'headroom--not-bottom': this.isNotBottom
      }
    }
  },
  methods: {
    attachEvent () {
      if (!this.initialised) {
        this.lastKnownScrollY = this.getScrollY()
        this.initialised = true
      }
    },
    getScrollY () {
      return window.pageYOffset
    },
    update () {
      if(!this.enabled) {
        return;
      }

      this.currentScrollY = this.getScrollY()

      if (this.isOutOfBounds(this.currentScrollY)) { // Ignore bouncy scrolling in OSX
        return
      }

      if (this.currentScrollY <= this.offset) {
        this.top()
      } else {
        this.notTop()
      }

      if (this.currentScrollY + this.getViewportHeight() >= this.getScrollerHeight()) {
        this.bottom()
      } else {
        this.notBottom()
      }

      const scrollAction = this.checkToleranceAndDirection(this.currentScrollY)

      if (scrollAction === 'pinned') {
        this.pin()
      } else if (scrollAction === 'unpinned' || this.currentScrollY < this.offsetTidy) {
        this.unpin()
      }

      this.lastKnownScrollY = this.currentScrollY
    },
    //  * determines if the tolerance has been exceeded
    //  * @param  {int} currentScrollY the current scroll y position
    //  * @return {bool} true if tolerance exceeded, false otherwise
    isOutOfBounds (currentScrollY) {
      let pastTop = currentScrollY < 0
      let pastBottom = currentScrollY + this.getScrollerPhysicalHeight() > this.getScrollerHeight()

      return pastTop || pastBottom
    },
    getScrollerPhysicalHeight () {
      return this.getViewportHeight()
    },
    //  * Gets the height of the viewport
    //  * @see http://andylangton.co.uk/blog/development/get-viewport-size-width-and-height-javascript
    //  * @return {int} the height of the viewport in pixels
    getViewportHeight () {
      return window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
    },
    getScrollerHeight: function () {
      return this.getDocumentHeight()
    },
    // * Gets the height of the document
    // * @see http://james.padolsey.com/javascript/get-document-height-cross-browser/
    // * @return {int} the height of the document in pixels
    getDocumentHeight () {
      let body = document.body
      let documentElement = document.documentElement

      return Math.max(
        body.scrollHeight, documentElement.scrollHeight,
        body.offsetHeight, documentElement.offsetHeight,
        body.clientHeight, documentElement.clientHeight
      )
    },
    //  * determines if the tolerance has been exceeded
    //  * @param  {int} currentScrollY the current scroll y position
    //  * @return {bool} true if tolerance exceeded, false otherwise
    toleranceExceeded: function (currentScrollY, direction) {
      return Math.abs(currentScrollY - this.lastKnownScrollY) >= this.tolerance[direction]
    },
    checkToleranceAndDirection (currentScrollY) {
      let scrollDirection = currentScrollY > this.lastKnownScrollY ? 'down' : 'up'
      let toleranceExceeded = this.toleranceExceeded(currentScrollY, scrollDirection)
      let action

      if (this.shouldUnpin(currentScrollY, toleranceExceeded)) {
        action = 'unpinned'
      } else if (this.shouldPin(currentScrollY, toleranceExceeded)) {
        action = 'pinned'
      }
      return action
    },
    //  * determine if it is appropriate to unpin
    //  * @param  {int} currentScrollY the current y scroll position
    //  * @param  {bool} toleranceExceeded has the tolerance been exceeded?
    //  * @return {bool} true if should unpin, false otherwise
    shouldUnpin: function (currentScrollY, toleranceExceeded) {
      let scrollingDown = currentScrollY > this.lastKnownScrollY
      let pastOffset = currentScrollY >= this.offset

      return scrollingDown && pastOffset && toleranceExceeded
    },
    //  * determine if it is appropriate to pin
    //  * @param  {int} currentScrollY the current y scroll position
    //  * @param  {bool} toleranceExceeded has the tolerance been exceeded?
    //  * @return {bool} true if should pin, false otherwise
    shouldPin: function (currentScrollY, toleranceExceeded) {
      let scrollingUp = currentScrollY < this.lastKnownScrollY
      let pastOffset = currentScrollY >= this.offset

      return (scrollingUp && toleranceExceeded) && pastOffset
    },
    top () {
      if (!this.isTop) {
        this.isTop = true
        this.isNotTop = false
      }
    },
    notTop () {
      if (!this.isNotTop) {
        this.isTop = false
        this.isNotTop = true
      }
    },
    bottom () {
      if (!this.isBottom) {
        this.isBottom = true
        this.isNotBottom = false
      }
    },
    notBottom () {
      if (!this.isNotBottom) {
        this.isNotBottom = true
        this.isBottom = false
      }
    },
    pin () {
      if (!this.isPinned) {
        Vue.nextTick(() => {
          this.isUnpinned = false
          this.isPinned = true
        })
        // this.$emit('headroom-pin')
      }
    },
    unpin () {
      if (this.isPinned || !this.isUnpinned) {
        Vue.nextTick(() => {
          this.isPinned = false
          this.isUnpinned = true
        })
        // this.$emit('headroom-unpin')
      }
    }
  }
}
