import Vue from 'vue'
import vuexStore from './../store/vuex-store'
import { mapGetters, mapMutations } from 'vuex'
import headroomMixin from './../mixins/headroom.vue'

var pageConfig = {
  animationSlideTiming: 2000,
  animationDetailTiming: 1000
}

export default function () {
  new Vue({
    mixins: [headroomMixin],
    el: '#site-header',
    store: vuexStore,
    mounted() {
        var self = this;
        window.addEventListener("keydown", function(e){
            
            if (self.showMenu || self.showSiteSearch){
                if (e.type === "keydown" && e.keyCode === 27){
                    if (self.showMenu){
                        self.toggleMenu();
                    }else{
                        self.toggleSiteSearch();
                    }
                }
            }
        });
    },
    computed: {
      ...mapGetters({
        showMenu: 'showMenu',
        showSiteSearch: 'showSiteSearch',
        isModalOpen: 'isModalOpen',
        showcaseDetailViewExpanded: 'showcase/showcaseDetailViewExpanded',
        isShowcaseDisabled: 'showcase/isShowcaseDisabled',
        performanceGridTransitionIn: 'showcase/performanceGridTransitionIn',
        performanceGridTransitionOut: 'showcase/performanceGridTransitionOut'
      }),
      showOverlay () {
        return (this.showMenu || this.showSiteSearch)
      }
    },
    methods: {
      ...mapMutations({
        toggleMenu: 'toggleMenu',
        toggleSiteSearch: 'toggleSiteSearch',
        setShowcaseDetailView: 'showcase/setShowcaseDetailView',
        setIsShowcaseDisabled: 'showcase/setIsShowcaseDisabled'
      })
    }
  })
}
