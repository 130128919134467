import Vue from 'vue'
import vuexStore from './../store/vuex-store'
import { mapGetters } from 'vuex'
import {TimelineLite} from 'gsap'
import siteSearchTypeahead from './../mixins/siteSearchTypeahead.vue'

export default function () {
  new Vue({ // eslint-disable-line
    el: '#site-overlay',
    mixins: [siteSearchTypeahead],
    store: vuexStore,
    data: {
      keywordSearchModel: '',
      siteNavTween: undefined
    },
    mounted () {
      this.setupTweens()
    },
    computed: {
      ...mapGetters([
        'showMenu',
        'showSiteSearch'
      ]),
      showOverlay () {
        return (this.showMenu || this.showSiteSearch)
      }
    },
    watch: {
      showMenu (isOpen) {
        (isOpen)
          ? this.animateInNavigation()
          : this.animateOutNavigation()
      },
      showOverlay(isOpen) {
        (isOpen)
          ? document.body.classList.add('page--no-scroll')
          : document.body.classList.remove('page--no-scroll')
      }
    },
    methods: {
      setupTweens () {
        let {nav, subnav, auxillary} = this.$refs

        nav = this.convertNodeListToArray(nav.querySelectorAll('.site-nav__item'))
        subnav = this.convertNodeListToArray(subnav.querySelectorAll('.site-nav__item'))

        this.siteNavTween = new TimelineLite()

        this.siteNavTween.staggerFromTo([...nav, ...subnav, auxillary], 0.5,
          {opacity: 0, y: 10, timeScale: 0.75},
          {opacity: 1, y: 0, ease: 'Power1.easeOut', timeScale: 1},
          0.15)
          .pause()
      },
      animateInNavigation () {
        this.siteNavTween.play().delay(1)
      },
      animateOutNavigation () {
        this.siteNavTween.reverse().delay(0)
      },
      convertNodeListToArray (nodelist) {
        return Array.prototype.slice.call(nodelist)
      }
    }
  })
}
