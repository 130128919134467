import Vue from 'vue'
import {TweenLite} from 'gsap'
import collapsibleSections from './../mixins/collapsibleSections.vue'
// import tabbyArea from './../mixins/tabbyArea.vue'


//changed to use tabbyarea and collapsiblesections mixin instead of duplicated code code


// var config = {
//   animationTiming: 350 // should be in line with animation.scss file
// }

export default function () {
  if (!document.getElementById('people-detail-app')) {
    return false
  }

  new Vue({ // eslint-disable-line
    el: '#people-detail-app',
    mixins: [collapsibleSections],
    data: function () {
      return {
        activeSectionLabel: '',
      }
    },
    created () {

    },
    watch: {

    },
    methods: {
      sectionScrollWatch (message) {
        if (message.new === 'aboveMiddle') {
          this.activeSectionLabel = message.moduleName
        }
      },
   
    }
  })
}
