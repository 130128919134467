import Vue from 'vue'
import { mapGetters } from 'vuex'
import { mapMutations } from 'vuex'

Vue.component('CareersBriefs', {
  computed: {
    ...mapGetters({
      'showCareersBriefs': 'careersLanding/showCareersBriefs'
    })
},
methods: {
    ...mapMutations({
      'toggleCareersBriefs': 'careersLanding/toggleBriefsView'
    }),
    actionForCareersBriefs () {
        //$showcase-desktop--careers
        this.toggleCareersBriefs()
    }
}

})
