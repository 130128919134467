import Vue from 'vue'


var config = {
  animationTiming: 350  //should be in line with animation.scss file
}

Vue.component('expandable-services', {
  data: function () {
  	return {
      isExpanded: false,
      cachedHeight: '',
      collapsedHeight: ''
    }
  },
  methods: {
    toggleNode () {
    	this.isExpanded = !this.isExpanded
    },
    expandContent (heightToExpand) {
      this.cachedHeight = this.$refs.collapsedContent.clientHeight
      
      requestAnimationFrame( () => {
        this.collapsedHeight = heightToExpand + 'px'
      })
    },
    collapseContent (currentHeight) {
      requestAnimationFrame( () => {
        this.collapsedHeight = currentHeight + 'px'

        requestAnimationFrame( () => {
          this.collapsedHeight = this.cachedHeight + 'px'
        });
      });
    },
    removeListener(element, expandState) {
      element.addEventListener('transitionend', () => {
        // remove this event listener so it only gets triggered once
        element.removeEventListener('transitionend', void(0))

        if (expandState) {
          // remove "height" from the element's inline styles, so it can return to its initial value
          this.collapsedHeight = 'auto'
        } else {
          // remove "height" from the element's inline styles, so it can return to its initial value
          this.collapsedHeight = this.cachedHeight  + 'px'
        }
      })
    }
  },
  watch: {
    isExpanded: function(expandNode) {
      const el = this.$refs.collapsedContent;
      const sectionHeight  = el.scrollHeight;

      (expandNode) 
        ? this.expandContent(sectionHeight)
        : this.collapseContent(sectionHeight)
      
      this.removeListener(el, expandNode)
    }
  }
});
