import Vue from 'vue'
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  data: {
    //assumes existence of keywordSearchModel variable, set from page app (site-overlay) or search landing mixin
    siteSearchTypeahead: [],
    timer: undefined,
  },
  mounted () {

  },
  computed: {

    showSiteSearchTypeahead() {
      return this.siteSearchTypeahead.length > 0;
    },
  },
  watch: {
    keywordSearchModel(newVal, oldVal) {
      //spec says 3 characters starts typeahead

      if(newVal && newVal.length > 2){
        this.startTypeaheadTimer();
      }
      else {
        this.siteSearchTypeahead = [];
      }
    }
    
  },
  methods: {

    doSearchForTypeahead(){
      axios.post('/api/sitecore/SiteSearchApi/Typeahead?keyword=' + this.keywordSearchModel)
      .then(this.onTypeAheadSuccess)
      .catch(this.onTypeAheadError)
    },

    startTypeaheadTimer(){
      console.log('timer restart');
      clearTimeout(this.timer)
      //wait for a bit for user to finish typing before triggering typeahead
      this.timer = setTimeout(this.typeAheadTimerComplete, 300);
    },

    typeAheadTimerComplete() {
      console.log('timer complete');
      this.doSearchForTypeahead()
    },

    onTypeAheadSuccess(response) {
      this.siteSearchTypeahead = response.data.Results
    },

    resetTypeahead(){
      this.siteSearchTypeahead = [];
      clearTimeout(this.timer)
    },

    onTypeAheadError (response) {
      console.error(response);
    },
  }
}
