import Vue from 'vue'

var config = {
  animationTiming: 350  // should be in line with animation.scss file
}

Vue.component('services-areas', {
  data: function () {
    return {
      activeServiceArea: 'practices',
      // practicesListing: [],
      collapsedHeight: '',
      currentFilteredPractice: 'all' // Default category
    }
  },
  mounted () {
    // this.practicesListing = window.json.practicesListing

    this.setInitialState()
  },
  computed: {
    isFiltersDisabled () {
      return this.activeServiceArea.trim() !== 'practices'
    },
    // filteredPractices () {
    //   if (this.currentFilteredPractice === 'all') {
    //     return []
    //   }

    //   let parentService = {}

    //   let filterList = this.practicesListing.filter((serviceItem) => {

    //     // if (serviceItem.category === this.currentFilteredPractice && serviceItem.parentPractice === 'true') {
    //     if (serviceItem.Guid === this.currentFilteredPractice && serviceItem.parentGuid == null) {
    //       parentService = serviceItem

    //       return false
    //     }
    //     return serviceItem.ParentGuid === this.currentFilteredPractice
    //   })

    //   if (parentService.parentPractice !== undefined) {
    //     filterList.unshift(parentService)
    //   }

    //   return filterList
    // }
  },
  methods: {
    // Setting tab State
      isActiveArea (servicesCategory) {
      return this.activeServiceArea.trim() === servicesCategory.trim()
    },
    setActiveArea (servicesCategory) {
      this.activeServiceArea = servicesCategory
    },
    // Settings Filtered Practice Areas State
    filterPracticeAreaBy (parentPracticeGuid) {
      if (this.isFiltersDisabled) {
        return
      }
      this.currentFilteredPractice = parentPracticeGuid
    },

    isActiveFilter (slugLabel) {
      return slugLabel === this.currentFilteredPractice
    },
    
    setInitialState () {
      let renderedActiveTab = document.querySelector('.initial-active')

      this.activeServiceArea = renderedActiveTab.innerText.toLowerCase()
    },

    shouldShowPractice(parentGuid, isOverview, isServicePointer){
      if(this.isFiltersDisabled)
        return false;

        //don't show the "overview" ones or the duplicates from service pointers when filter is set to all
      if(this.currentFilteredPractice === 'all')
        return !isOverview && !isServicePointer;
      
      return this.currentFilteredPractice === parentGuid
    }
  }
})
