import Vue from 'vue'

Vue.directive('Expand', {
  inserted (el, { value }) {
    const config = normalize(value)
    el.style['overflow'] = 'hidden'
    handleDynamicHeight(el)
    setFullHeight(el, config)
    enableTransitions(el, config)
  },
  update (el, { value }) {
    const config = normalize(value)
    setFullHeight(el, config)
  }
})

const normalize = (value) =>
  ( value &&
    typeof value.isExpanded === 'boolean' &&
    typeof value.duration === 'number'
  )
    ? value
    : { isExpanded: !!value, duration: 300 }

const setFullHeight = (el, config) => {
  if (config.isExpanded) {
    el.style['height'] = el.fullHeight
    setTimeout(_ => { el.style['height'] = '' }, config.duration + 10)
  } else {
    window.requestAnimationFrame(_ => {
      el.style['height'] = el.fullHeight
      setTimeout(_ => { el.style['height'] = 0 }, 0)
    })
  }
}

const handleDynamicHeight = (el) => {
  const updateFullHeight = (el) => {
    const originalHeightStyle = el.style['height']
    el.style['height'] = ''
    el.fullHeight = el.offsetHeight + 'px'
    el.style['height'] = originalHeightStyle
  }

  updateFullHeight(el)
  window.addEventListener('resize', _ => updateFullHeight(el))
}

const enableTransitions = (el, config) =>
  window.requestAnimationFrame(_ =>
    setTimeout(_ => {
      el.style['transition'] = `height ${config.duration}ms ease-in-out`
    }, 100)
  )